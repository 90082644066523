import avatar_01 from "../assets/gislene-photo.png";
import avatar_02 from "../assets/tiago-photo.png";
import avatar_03 from "../assets/paula-photo.png";

export const clients = [
  {
    id: "1",
    image: avatar_01,
    name: "Gislene M.",
    stars: 5,
    description:
      "Ótima agência de viagens! O pessoal é super atencioso e os preços são os melhores. Minha viagem foi incrível e ocorreu super bem. Vou planejar a próxima com eles, com certeza! Recomendo muito!",
  },
  {
    id: "2",
    image: avatar_02,
    name: "Tiago N.",
    stars: 5,
    description:
      "Com certeza uma das melhores experiências que já tive em viagens, todo apoio e suporte que a MikeTur prestou facilitou muito e só agregou para que fosse tudo mais incrível!!",
  },
  {
    id: "3",
    image: avatar_03,
    name: "Paula F.",
    stars: 5,
    description:
      "Estava pesquisando passagens de avião para Porto Seguro em um feriado, todos horários estavam caros, pedi ajuda para o Kaio e ele conseguiu passagens em um ótimo horário e mais baratas. Recomendo a Miketur. Excelente atendimento.",
  },
];
