import { IconStar } from "../Icons";

export const Stars = () => {
  return (
    <div>
      <IconStar />
      <IconStar />
      <IconStar />
      <IconStar />
      <IconStar />
    </div>
  );
};
